import { DateTime, Interval } from 'ts-luxon'
import { type AwardFormType, type VestingPeriodType } from '~/pages/awards/edit/[id]'
import AssetProcess, { AssetProcessTypes } from '~/models/AssetProcess'
import { type WithURLs } from '~/models/BaseModel'

export type VestingTypes = 'no_vesting' | 'time_based' | 'performance_based'
export type AccelerationType = 'none' | 'single_trigger' | 'double_trigger'

export type VestingMilestone = {
  id: number
  achieved: null | boolean
  allocateAssets: null | number
  awardAmount: number
  awardPercentage: number
  milestoneNote: null | string
  performanceCriteria: string
  targetDate: string
  vestedOnDate: null | string
}
export type VestingMilestoneForm = Pick<
  VestingMilestone,
  'awardPercentage' | 'performanceCriteria' | 'targetDate'
>

export type VestingData = {
  allocation: 'M' | 'Q' | 'Y'
  cliff: number
  distribution: number[]
  duration: number[]
  vesting: number
  vestingStartDate?: string
  latestVestingEndDate?: string | null
}

type VestingPause = {
  id: number
  reason: string
  startDate: string
  endDate: string
}

type AwardData = {
  amount: number
  initialValue: number
  originalTotalValue: number
  grantDate: string
  valuationDate: string
  note: string
  pricePerAsset?: number
  vestingData?: VestingData
  vestingMilestones?: VestingMilestoneForm[]
  vestingType?: VestingTypes
  acceleration?: AccelerationType
  accelerationPeriod?: number
}

export type GraphData = {
  date: string
  value: number
}

export const vestingDataToPeriods = (vestingData: VestingData): VestingPeriodType[] => {
  return vestingData?.distribution.map((percent: number, i: number) => ({
    duration: vestingData.duration[i] as number,
    percent,
    type: i === 0 && vestingData.duration[i] === vestingData.cliff ? 'cliff' : 'vesting'
  }))
}

export const getVestingTypeLabel = (vestingType: VestingTypes): string => {
  switch (vestingType) {
    case 'no_vesting':
      return 'No vesting'
    case 'time_based':
      return 'Time-based vesting'
    case 'performance_based':
      return 'Performance-based vesting'
  }
}

export const awardFormToVestingData = (
  values: AwardFormType,
  addVestingStart = false
): VestingData | null => {
  if (!values.allocation || values.periods.length === 0 || values.vestingType !== 'time_based') {
    return null
  }

  const vestingData: VestingData = {
    allocation: values.allocation,
    cliff: values.periods.find((item) => item.type === 'cliff')?.duration ?? 0,
    distribution: values.periods.map((item) => Number(item.percent)),
    duration: values.periods.map((item) => Number(item.duration)),
    vesting: values.periods.reduce((sum, cur) => sum + Number(cur.duration), 0)
  }

  addVestingStart && (vestingData.vestingStartDate = values.vestingStartDate || undefined)

  return vestingData
}

export default class Award extends AssetProcess implements WithURLs {
  static label = 'Award'

  public data!: AwardData

  public graph!: GraphData[]

  public totalAssetCount?: number
  public totalInitialValue?: number
  public vestedAssetCount?: number
  public vestedAssetValue?: number
  public isMissingPlaceholders?: boolean
  public vestingPauses?: VestingPause[]
  public vestingMilestones?: VestingMilestone[]
  public buyback?: { unvestedMax: number; vestedMax: number; transactionType: AssetProcessTypes }

  public get dataToPeriods(): VestingPeriodType[] {
    return this.data?.vestingData ? vestingDataToPeriods(this.data.vestingData) : []
  }

  public get hasVesting(): boolean {
    return !!this.data?.vestingData
  }

  public get vestingStartDate(): DateTime {
    return DateTime.fromISO((this.data.vestingData as VestingData).vestingStartDate as string)
  }

  public get vestingEndDate(): DateTime {
    return DateTime.fromISO((this.data.vestingData as VestingData).vestingStartDate as string)
      .plus({ months: (this.data.vestingData as VestingData).vesting })
      .minus({ days: 1 })
  }

  public get vestingDurationMonths() {
    const calcMonth = Math.floor(DateTime.now().diff(this.vestingStartDate, ['months']).months)

    return Math.min(Math.max(calcMonth, 0), this.totalVestingMonths)
  }

  public get totalVestingMonths(): number {
    if (this.data.vestingData?.latestVestingEndDate === null) {
      return 0
    }
    if (this.data.vestingData?.vestingStartDate && this.data.vestingData?.latestVestingEndDate) {
      const vestingStartDate = DateTime.fromISO(this.data.vestingData?.vestingStartDate)
      const vestingEndDate = DateTime.fromISO(this.data.vestingData?.latestVestingEndDate)
      const vestingPeriod = Interval.fromDateTimes(
        vestingStartDate,
        vestingEndDate.plus({ days: 1 })
      )
      return Math.floor(vestingPeriod.length('months'))
    }
    return this.data.vestingData?.vesting || 0
  }

  public get vestingProgressPercent(): number {
    if (this.totalVestingMonths === 0) {
      return 100
    }

    if (this.vestingDurationMonths === this.totalVestingMonths) {
      return 100
    }

    if (!this.hasVesting) {
      return 100
    }

    const today = DateTime.now().startOf('day')
    if (today < this.vestingStartDate) {
      return 0
    }

    if (today > this.vestingEndDate) {
      return 100
    }

    return Math.floor(
      (100 * today.diff(this.vestingStartDate).milliseconds) /
        this.vestingEndDate.diff(this.vestingStartDate).milliseconds
    )
  }

  public get isPerformanceBased(): boolean {
    return this.data.vestingType === 'performance_based'
  }

  public get isTimeBased(): boolean {
    return this.data.vestingType === 'time_based'
  }

  public get vestingTypeLabel(): string | null {
    return this.data.vestingType ? getVestingTypeLabel(this.data.vestingType) : null
  }

  public get urlDetail(): string {
    return `/awards/${this.id}`
  }

  public get urlEdit(): string {
    return `/awards/edit/${this.id}`
  }

  public get urlLabel(): string {
    return this.displayId
  }

  public get isCustom(): boolean {
    return !!this.data.note
  }
}
